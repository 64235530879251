import React from 'react';
import './Services.css'; 
import data from '../../assets/data_analytics.png';
import AI from '../../assets/AI_last.jpg.avif';
import BI from '../../assets/data99.avif';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReact } from '@fortawesome/free-brands-svg-icons';
import { faDiagramProject } from '@fortawesome/free-solid-svg-icons';
import { faMagnifyingGlassChart } from '@fortawesome/free-solid-svg-icons';
import { faBrain } from '@fortawesome/free-solid-svg-icons';



const Services = () => {
  return (
    <section id="services">
      <div className="container" data-aos="fade-up">

        <header className="section-header">
          <h3>Services</h3>
          <p>
          Our team places a strong emphasis on providing actionable insights with excellent service and support along all stages of the project.
          </p>
        </header>

        <div className="row services-cols">

          <div className="col-md-4" data-aos="fade-up" data-aos-delay="100">
            <div className="services-col">
              <div className="img">
                <img src={BI} alt="" className="img-fluid" />
                <div className="icon"><FontAwesomeIcon icon={faBrain} size="2x" className="iccon" />

</div>
              </div>
              <h2 className="title"><a href="data_science">Data Science</a></h2>
              <p>
              Unlock the full potential of your data through our personalized Data Science consultancy services. We specialize in tailoring solutions to suit your unique needs.
              </p>
            </div>
          </div>

          <div className="col-md-4" data-aos="fade-up" data-aos-delay="200">
            <div className="services-col">
              <div className="img">
                <img src={AI} alt="" className="img-fluid" />
                <div className="icon"><FontAwesomeIcon icon={faDiagramProject} size="2x" className="iccon"/></div>
              </div>
              <h2 className="title"><a href="/ai">Artificial Intelligence</a></h2>
              <p>
              
Maximize the benefits of Artificial Intelligence  with our expert consultancy services. Our dedicated team is here to help you unlock valuable insights and implement cutting-edge AI solutions. 
              </p>
            </div>
          </div>

          <div className="col-md-4" data-aos="fade-up" data-aos-delay="300">
            <div className="services-col">
              <div className="img">
                <img src={data} alt="" className="img-fluid" />
                <div className="icon"><FontAwesomeIcon icon={faMagnifyingGlassChart} size="2x" className="iccon" /></div>
              </div>
              <h2 className="title"><a href="data_analytics">Data Analytics</a></h2>
              <p>
              Leverage the expertise of our Data Analytics consultants to maximize the value derived from your data. Our professionals specialize in unlocking key insights, providing strategic guidance for informed decision-making . 
              </p>
            </div>
          </div>

        </div>

      </div>
    </section>
  );
};

export default Services;
