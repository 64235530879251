import React from 'react';
import './AuthenticationPage.css'; // Include your CSS file
import Navbar from '../../components/navbar/navbar';

function AuthenticationPage() {
  const handleSignUpClick = () => {
    const container = document.getElementById('container_login');
    container.classList.add('right-panel-active');
  };

  const handleSignInClick = () => {
    const container = document.getElementById('container_login');
    container.classList.remove('right-panel-active');
  };

  return (
    <div className="login">
       
    <div className="container_login" id="container_login">
      <div className="form-container sign-up-container">
        <form action="#">
          <h1>Create Account</h1>
          <div className="social-container">
            <a href="#" className="social"><i className="fab fa-facebook-f"></i></a>
            <a href="#" className="social"><i className="fab fa-google-plus-g"></i></a>
            <a href="#" className="social"><i className="fab fa-linkedin-in"></i></a>
          </div>
          <span>or use your email for registration</span>
          <input type="text" placeholder="Name" />
          <input type="email" placeholder="Email" />
          <input type="password" placeholder="Password" />
          <button onClick={handleSignUpClick}>Sign Up</button>
        </form>
      </div>
      <div className="form-container sign-in-container">
        <form action="#">
          <h1>Client Portal</h1>
          <br></br>
          <br></br>
          
          
          <input type="email" placeholder="Email" />
          <input type="password" placeholder="Password" />
          <a href="#">Forgot your password?</a>
          <button>Sign In</button>
        </form>
      </div>
      <div className="overlay-container">
        <div className="overlay">
          <div className="overlay-panel overlay-left">
            <h1>Welcome Back!</h1>
            <p>To keep connected with us please login with your personal info</p>
            <button className="ghost" onClick={handleSignInClick}>Sign In</button>
          </div>
          <div className="overlay-panel overlay-right">
            <h1>DSF Analytics</h1>
            <p>Sign in here to access your applications</p>
            <button className="ghost" onClick={handleSignUpClick}>Contact Us</button>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default AuthenticationPage;
