import React , { useState } from 'react';
import './ContactSection.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt , faPhoneVolume ,  faEnvelope  } from '@fortawesome/free-solid-svg-icons';


const ContactSection = () =>  {
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    try {
      const response = await fetch('https://dsf-roan.vercel.app/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: e.target.name.value,
          email: e.target.email.value,
          subject: e.target.subject.value,
          message: e.target.message.value,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to send email');
      }

      setSubmitting(false);
      setSubmitSuccess(true);
    } catch (error) {
      console.error(error);
      setSubmitting(false);
      setSubmitError('Failed to send email');
    }
  };

  return (
    
    <section id="contact" class="contact ">
      <div class="container" data-aos="fade-up">

      <header className="section-header">
          <h3>Contact Us</h3>
          <p>
          Let’s talk about your business goals and how our unique approach will help you establish or revamp your AI and Data Science strategies
          </p>
        </header>

        <div class="row">
          <div class="col-lg-6">
            <div class="info-box mb-4">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="contact_icon" />
              <h3>Our Address</h3>
              <p>2040 Columbia St San Diego, CA 92101 - United States</p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6">
            <div class="info-box  mb-4">
            <FontAwesomeIcon icon={ faEnvelope } className="contact_icon" />
              <h3>Email Us</h3>
              <p>contact@dsfanalytics.com</p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6">
            <div class="info-box  mb-4">
            <FontAwesomeIcon icon={faPhoneVolume} className="contact_icon" />
              <h3>Call Us</h3>
              <p>+1 224 433-3904</p>
            </div>
          </div>

        </div>

        <div class="row">

          <div class="col-lg-6 ">
          <iframe
      className="mb-4 mb-lg-0"
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621"
      frameBorder="0"
      style={{ border: 0, width: '100%', height: '384px' }}
      allowFullScreen
      title="Google Map Embed"
    ></iframe>
          </div>

          <div class="col-lg-6">
          <form onSubmit={handleSubmit} className="php-email-form">

              <div class="row">
                <div class="col-md-6 form-group">
                  <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" required/>
                </div>
                <div class="col-md-6 form-group mt-3 mt-md-0">
                  <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" required/>
                </div>
              </div>
              <div class="form-group mt-3">
                <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" required/>
              </div>
              <div class="form-group mt-3">
                <textarea class="form-control" name="message" rows="5" placeholder="Message" required></textarea>
              </div>
              <div class="my-3">
                <div class="loading">Loading</div>
                <div class="error-message"></div>
                <div class="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <div class="text-center"><button type="submit">Send Message</button></div>
            </form>
          </div>

        </div>

      </div>
    </section>
  );
};

export default ContactSection;