import 'bootstrap-icons/font/bootstrap-icons.css'; // Ensure this import is present
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './navbar.css';

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const activeStyle = {
    color: 'gold', // Change to your preferred shade of gold
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
    if (isMobileMenuOpen) {
      setDropdownOpen(false);
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
    setDropdownOpen(false);
  };

  return (
    <header id="header" className="fixed-top d-flex align-items-center">
      <div className="container d-flex align-items-center">
        <div className="logo me-auto">
          <h1><NavLink to="/" ><strong>DSF</strong></NavLink></h1>
        </div>
        <nav id="navbar" className={`navbar order-last order-lg-0 ${isMobileMenuOpen ? 'navbar-mobile' : ''}`}>
          <ul>
            <li><NavLink exact to="/" activeStyle={activeStyle} className="nav-link scrollto" onClick={closeMobileMenu}>Home</NavLink></li>
            <li><NavLink to="/about" activeStyle={activeStyle} className="nav-link scrollto" onClick={closeMobileMenu}>About</NavLink></li>
            <li className={`dropdown ${isDropdownOpen ? 'dropdown-active' : ''}`}>
              <a href="#services" className="nav-link scrollto" onClick={(e) => { e.preventDefault(); toggleDropdown(); }}>Services</a>
              <ul className={`${isDropdownOpen ? 'd-block' : ''}`}>
                <li><NavLink to="/data_science" activeStyle={activeStyle} className="nav-link scrollto" onClick={closeMobileMenu}>Data Science</NavLink></li>
                <li><NavLink to="/ai" activeStyle={activeStyle} className="nav-link scrollto" onClick={closeMobileMenu}>AI</NavLink></li>
                <li><NavLink to="/data_analytics" activeStyle={activeStyle} className="nav-link scrollto" onClick={closeMobileMenu}>Data Analytics</NavLink></li>
              </ul>
            </li>
            <li><NavLink to="/casestudies" activeStyle={activeStyle} className="nav-link scrollto" onClick={closeMobileMenu}>Case Studies</NavLink></li>
            <li><NavLink to="/smartdoc" activeStyle={activeStyle} className="nav-link scrollto" onClick={closeMobileMenu}>Smart Doc</NavLink></li>
            <li><NavLink to="/contact" activeStyle={activeStyle} className="getstarted scrollto" onClick={closeMobileMenu}>Contact</NavLink></li>
          </ul>
          <i className={`bi ${isMobileMenuOpen ? 'bi-x' : 'bi-list'} mobile-nav-toggle`} onClick={toggleMobileMenu}></i>
        </nav>
      </div>
      {isMobileMenuOpen && <div className="overlay" onClick={closeMobileMenu}></div>}
    </header>
  );
};

export default Navbar;
