import horse from '../assets/horse_image.png';
import grocery from '../assets/grocery_cart.png';
import energy from '../assets/energy.png';
import MIAMI from '../assets/MIA.png';
/************************** */


import brainImage from '../assets/mia_sol11.png';
import dataModelingImage from '../assets/mia_sol2.png';
import nlpImage from '../assets/mia_sol3.png';
import python from '../assets/Python-Logo.png';
import aws from '../assets/aws.png';
import tableau from '../assets/tableau.png';
import learn from '../assets/learn.png';

/********************************* */
import pytorch from '../assets/pytorch.png';
import search from '../assets/energy1.png';
import neuron from '../assets/energy2.png';
/******************************* */
import extract from '../assets/extract.png';
import deep from '../assets/deep-learning55.png';
import azure from '../assets/azure.png';
import tensorflow from '../assets/tensorflow.png';
import keras from '../assets/keras.png';
import powerbi from '../assets/powerBI.png';
/************************************** */

import cloud from '../assets/horse1.png';
import database from '../assets/database_horse.png';
import rocket from '../assets/rocket.png';


const CaseStudiesData = [
    {
      id: 1,
      image: horse,
      title: 'Arquana Horse Auction',
      description: 'Description for Arquana Horse Auction case study.',
      link: 'blog-details.html',
      category: ['AI', 'Data Analytics'],
      type: 'Research',
      overview: `Fal Stud is one of the largest horse racing stables in Morocco and France with
      over 900 horses.
      The goal of our project was to help them make better purchasing decisions at
      the Arqana Auction, one of the world's largest horse auctions.`,
        solutionSteps: [
          {
            icon: cloud,
            title: 'Implement a scalable architecture on AWS Cloud',
          },
          {
            icon: database,
            title: 'Data collection and storage',
          },
          {
            icon: rocket,
            title: 'Build state-of-the-art Machine Learning predictive models',
          },
          
        ],
        technologies: [python, aws, tableau , tensorflow],
        percent: '78% Accuracy with Machine Learning model',
        increase: ' 420,000 € Saved by the model at the auction.'

      
      
    },


    {
      id: 2,
      image: grocery,
      title: 'Walmart Store Sales',
      description: 'Description for Walmart Store Sales case study.',
      link: 'blog-details.html',
      category: ['Data Science'],
      type: 'Client',
      overview: `Walmart is the world's biggest retailer with over 10,000 stores in 20 countries.
      We used time-series forecasting to predict weekly department-wide sales for
      30 Walmart stores located in California for an upcoming period of 6 months.`,
        solutionSteps: [
          {
            icon: extract,
            title: 'Extract transactional dataset from Kaggle with 5 million instances',
          },
          {
            icon: search,
            title: 'Exploratory Data Analysis (EDA) and feature engineering',
          },
          {
            icon: deep,
            title: 'Implement Deep Learning Neural Networks to forecast future sales',
          },
          
        ],
        technologies: [python, azure, tensorflow , keras , powerbi],
        percent: '86% Accuracy with Machine Learning model',
        increase: 'Improved inventory, supply chain and human resources management'
    },
    {
        id: 3,
        image: energy,
        title: 'Sustainable Energy Consumption',
        description: 'Description for Sustainable Energy Consumption.',
        link: 'blog-details.html',
        category: ['Data Science'],
        type: 'Client',
        overview: `Accurate prediction of power consumption is a crucial aspect of electricity management to maximize energy efficiency. Our goal is to predict the electric power consumption every 10 minutes of three
        different power distribution networks located in the city of Tetouan, Morocco.`,
        solutionSteps: [
          {
            icon: search,
            title: 'ETL pipeline on AWS Cloud',
          },
          {
            icon: dataModelingImage,
            title: 'Feature engineering and model selection',
          },
          {
            icon: neuron,
            title: 'Deploy the best Neural Network model as a REST API',
          },
          
        ],
        technologies: [python, aws, tableau , pytorch],
        percent: '96% Accuracy with Deep Learning model',
        increase: '20 Million $ ln increased sales'
      },
      {
        id: 4,
        image: MIAMI,
        title: 'Real Estate Evaluation',
        description: 'The real estate industry is embracing the Big Data and Artificial Intelligence revolution.',
        
        category: ['Data Analytics','AI','Data Science'],
        type: 'Client',

        overview: `The real estate industry is embracing the Big Data and Artificial Intelligence revolution.
        To help one of South Florida's largest luxury real estate developers assess the sale of a 220 apartment building, we created a property valuation solution.`,
        solutionSteps: [
          {
            icon: brainImage,
            title: 'Create a flexible ETL pipeline on AWS Cloud',
          },
          {
            icon: dataModelingImage,
            title: 'Feature engineering and model training',
          },
          {
            icon: nlpImage,
            title: 'Deploy machine learning model as a REST API',
          },
          
        ],
        technologies: [python, aws, tableau , learn],
        percent: '91% Accuracy with Machine Learning model',
        increase: '20 Million $ ln increased sales'
        
      },
   
  ];
  
  export default CaseStudiesData;
  