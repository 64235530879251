import React from 'react';
import './FreeConsultation.css';

const FreeConsultation = () =>  {
  return (
    <section id="call-to-action" className="call-to-action">
      <div className="container" data-aos="fade-up">
        <div className="row justify-content-center">
          <div className="col-lg-6 text-center">
            <h3>Book a Free Consultation</h3>
            <p>Tell us a little bit about your business, and we’ll tell you how our Data Analytics consultants can help with a free consultation & proposal .</p>
            <a className="cta-btn" href="/contact">Free consultation</a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FreeConsultation;
