
import Footer from '../../components/footer/footer';
import Navbar from '../../components/navbar/navbar';

import ContactSection from '../../sections/ContactSection/ContactSection';





const ContactPage = () => (
  <div className='homepage'>
    <div className='homepage_container'>
    
        <Navbar />
        
        <ContactSection/>
        <Footer/>
    </div>
      
  </div>
);

export default ContactPage;