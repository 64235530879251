import React from 'react';
import data from '../../assets/energy.png';
import './CaseStudiesLoop.css';
import data2 from '../../assets/brain50.png';
import data3 from '../../assets/data-modelling50.png';
import data4 from '../../assets/natural-language-processing.png';
import percentage from '../../assets/percentage.png';
import increase from '../../assets/increase.png';
import { useParams } from 'react-router-dom';
import CaseStudiesData from '../../constants/CaseStudiesData';

import tech from '../../assets/Python-Logo.png';

import Navbar from '../../components/navbar/navbar';
import FreeConsultation from '../../sections/FreeConsultation/FreeConsultation';
import ContactSection from '../../sections/ContactSection/ContactSection';
import Footer from '../../components/footer/footer';
import Cases from '../../sections/Cases/Cases';
const CaseStudiesLoop = () => {

  const { id } = useParams();
  const selectedCase = CaseStudiesData.find((project) => project.id === parseInt(id));

  if (!selectedCase) {
    return <div>Projet non trouvé</div>;
  }

  
  const technologies = selectedCase.technologies || [];
  const solutionSteps = selectedCase.solutionSteps || [];

  return (
    <div className='homepage'>
    <div className='homepage_container'>
    <Navbar/>
    <section id="datascience" className="datascience ">
        
      <div className="container_science "style={{marginTop : '50px'}} >
        
        <div className="row">
          <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up">
            <h1>
              
             <span style={{ fontSize : '50px'}}>{selectedCase.title}</span>
            </h1>
            
            <br></br>
            <h2 data-aos-delay="400" style={{ fontSize : '25px'}}>
           
            {selectedCase.overview}
            </h2>
            
          </div>
          <div className="col-lg-6 datascience-img" data-aos="zoom-out" data-aos-delay="200">
            <img src={selectedCase.image} className="img-fluid"  style={{ maxWidth :'70%', height :'auto',  borderRadius: '10px', marginRight :'10px'}} alt="" />
          </div>
        </div>
      </div>
    </section>
    <br></br>
    
  
    <section id="sc_services" class="sc_services sc_services">
      <div class="container" data-aos="fade-up">
      
        <div class="section-title">
          <h2>Our Solution</h2>
 
        </div>

        <div class="row">
        {solutionSteps.map((step, index) => (
          <div key={index} className="col-lg-3 col-md-6 icon-box mx-auto" data-aos="zoom-in" data-aos-delay={index * 100}>
            <div className="icon">
              <img src={step.icon} className="img-ser" alt="" />
            </div>
            <h4 className="title2">{step.title}</h4>
          </div>
        ))}
         
          
        </div>

      </div>
      <br></br>
      <br></br>
      <div class="section-title">
          <h2>Technologies used</h2>
 
        </div>
      <div className="container"> 
      <div className="row justify-content-center"> 
      {technologies.map((tech, index) => (
            <div key={index} className="col-auto">
             
              <img src={tech} alt={`Logo ${index + 1}`} className="logo-img" />
            </div>
          ))}
        
        
      </div>
    </div>
    </section>
    <section id="sc_services" class="sc_services sc_services">
      <div className='results'>
      <div class="container" data-aos="fade-up">
    <div class="section-title">
          <h2>Results</h2>
 
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-6 icon-box mx-auto" data-aos="zoom-in" data-aos-delay="100">
            <div class="icon"><img src={percentage} class="img-ser" alt=""/></div>
            <h4 class="title2"> {selectedCase.percent}</h4>
           
          </div>
          <div class="col-lg-3 col-md-6 icon-box mx-auto" data-aos="zoom-in" data-aos-delay="200">
            <div class="icon"><img src={increase} class="img-ser" alt=""/></div>
            <h4 class="title2"> {selectedCase.increase}</h4>
            
          </div>
        </div>
        </div>
        </div>

     
    </section>
    <section id="sc_services" class="sc_services sc_services">
      <div class="container" data-aos="fade-up">
      <div class="section-title">
        
        <h2 >Similar Solution ?</h2>
        <Cases />
      </div>
      
      </div>
      </section>
    
    <FreeConsultation/>
    
    <Footer/>
    
    </div>
    </div>
  );
};

export default CaseStudiesLoop;
