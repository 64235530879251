import './HomePage.css';
import Footer from '../../components/footer/footer';
import Navbar from '../../components/navbar/navbar';
import AboutSection from '../../sections/AboutSection/AboutSection';
import CaseStudies from '../../sections/CaseStudies/CaseStudies';
import ContactSection from '../../sections/ContactSection/ContactSection';
import FreeConsultation from '../../sections/FreeConsultation/FreeConsultation';
import HeroSection from '../../sections/HeroSection/HeroSection';
import Services from '../../sections/Services/Services';
import Solution from '../../sections/Solution/Solution';
import Cases from '../../sections/Cases/Cases';
import Solution2 from '../../sections/Solution2/Solution2';




const HomePage = () => (
  <div className='homepage'>
    <div className='homepage_container'>
    
        <Navbar />
        <HeroSection/>
        <AboutSection/>
        <Services/>
        <Solution2/>
        <section id="sc_services" class="sc_services sc_services">
      <div class="container" data-aos="fade-up">
      <div class="section-title">
        
        <h2 >Projects </h2>
        <Cases  />
      </div>
      
      </div>
      </section>
        <FreeConsultation/>
        <ContactSection/>
        <Footer/>
    </div>
      
  </div>
);

export default HomePage;