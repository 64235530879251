import React from 'react';
import './FilterButton1.css';

const CategoryFilter = ({ categoryFilters, activeCategoryFilter, handleClickCategoryFilter }) => {
  return (
    <div id="filter-buttons" className="d-flex justify-content-center align-items-center" data-aos="fade-up" data-aos-delay="100">
      <h3 className="mr-3">Category:</h3>
      <ul className="list-unstyled d-flex mb-0">
        {categoryFilters.map((filter, index) => (
          <li key={index} className={activeCategoryFilter === filter ? 'active' : ''} onClick={() => handleClickCategoryFilter(filter)}>
            {filter}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CategoryFilter;
