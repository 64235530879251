import React from 'react';
import data from '../../assets/gold_ser.png';
import data2 from '../../assets/business.png';
import data3 from '../../assets/monitor.png';
import data4 from '../../assets/data-mining.png';
import data5 from '../../assets/service.png';
import data6 from '../../assets/database-architecture.png';
// import '../../pages/DataScience/DataScience.css';
import Navbar from '../../components/navbar/navbar';
import FreeConsultation from '../../sections/FreeConsultation/FreeConsultation';
import ContactSection from '../../sections/ContactSection/ContactSection';
import Footer from '../../components/footer/footer';
import Cases from '../../sections/Cases/Cases';
const DataAnalytics = () => {
  return (
    <div className='datasc'>
    <div className='datasc_container'>
    <Navbar/>
    <section id="datascience" className="datascience ">
        
      <div className="container_science">
        
        <div className="row">
          <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up">
            <h1>
              
Uncover Concealed Insights
Through Our<span> Data Analytics Consultants</span>
            </h1>
            <h2 data-aos-delay="400">
           
Our team of Data Analytics consultants empowers organizations to extract maximum value from their data, facilitating forward-thinking decision-making that propels business growth
            </h2>
            <div data-aos="fade-up" data-aos-delay="600">
              <div className="text-center text-lg-start">
              <a href="#about" className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">Get Started</a>
              </div>
            </div>
          </div>
          <div className="col-lg-6 datascience-img" data-aos="zoom-out" data-aos-delay="200">
            <img src={data} className="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </section>
    



    <section id="sc_services" class="sc_services sc_services">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Services</h2>
          <h1>
            Tailored <span> Data Analytics Consulting <br></br> </span>  Specifically for Your Business
            </h1>
          <p>
            <br></br>
            

            Irrespective of your immediate objectives or future aspirations, <br></br>our pioneering Data Analysts are here to expedite your market<br></br> presence and drive increased value in your endeavors</p>
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-6 icon-box mx-auto" data-aos="zoom-in" data-aos-delay="100">
            <div class="icon"><img src={data2} class="img-ser" alt=""/></div>
            <h4 class="title2">Business Intelligence</h4>
            <p class="description">We help you turn your data into actionable insights with our comprehensive Business Intelligence solutions, including reporting, data visualization, and analytics</p>
          </div>
          <div class="col-lg-3 col-md-6 icon-box mx-auto" data-aos="zoom-in" data-aos-delay="200">
            <div class="icon"><img src={data3} class="img-ser" alt=""/></div>
            <h4 class="title2">Predictive Analytics</h4>
            <p class="description">We specialize in developing predictive models and using statistical techniques to help you forecast trends, identify risks, and make informed decisions based on your data</p>
          </div>
          <div class="col-lg-3 col-md-6 icon-box mx-auto" data-aos="zoom-in" data-aos-delay="300">
            <div class="icon"><img src={data4} class="img-ser" alt=""/></div>
            <h4 class="title2">Data Mining</h4>
            <p class="description">We help you extract valuable insights from large datasets with our advanced data mining techniques, including clustering, classification, and association rule mining</p>
          </div>
          </div>
          <div class="row">
          <div class="col-lg-3 col-md-6 icon-box mx-auto mb-1" data-aos="zoom-in" data-aos-delay="100">
            <div class="icon"><img src={data5} class="img-ser" alt=""/></div>
            <h4 class="title2">Quality Control</h4>
            <p class="description">We provide comprehensive quality control services to help you ensure the accuracy, completeness, and reliability of your data, including data cleaning, validation, and reconciliation</p>
          </div>
          <div class="col-lg-3 col-md-6 icon-box mx-auto mb-1" data-aos="zoom-in" data-aos-delay="200">
            <div class="icon"><img src={data6} class="img-ser" alt=""/></div>
            <h4 class="title2">Data Architecture</h4>
            <p class="description">We help you design and implement scalable and secure data architectures that support your strategic objectives, including data warehousing, ETL, and big data platforms</p>
          </div>
          
        </div>

      </div>
    </section>
    
    <FreeConsultation/>
    
    <Footer/>
    
    </div>
    </div>
  );
};

export default DataAnalytics;
