import React from 'react';
import './HeroSection.css';
import heroImg from '../../assets/brain.png';
import 'bootstrap/dist/css/bootstrap.min.css';


const HeroSection = () => {
  return (
    <section id="hero">
      <div className="containerrr">
        <div className="row">
          <div className="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center" data-aos="fade-up">
            <div>
              <h1>Unlocking Insight, Empowering Innovation</h1>
              <h2>Our team of expert Data Scientists are passionate about leveraging state-of-the-art technologies to help you harness the power of data to make informed decisions, optimize operations, and drive growth</h2>
              <a href="#about" className="btn-get-started scrollto">Get Started</a>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="fade-left">
            <img src={heroImg} className="img-fluid " alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
