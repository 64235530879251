import React from 'react';
import './Solution.css';
import { CardChecklist } from 'react-bootstrap-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshakeAngle ,faSquarePollHorizontal , faChartLine , faDatabase} from '@fortawesome/free-solid-svg-icons';
function Solution() {
  return (
    <section id="solutions" className="solutions section-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Projects@DSF</h2>
          <p>Our solution approach is centered around five key principles:</p>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
            <FontAwesomeIcon icon={faHandshakeAngle} className='so_icon'/>
              <h4>Collaborative partnership</h4>
              <p>We work closely with our clients to understand their unique needs, challenges, and goals. By building a collaborative partnership, we can ensure that our solutions are tailored to the specific needs of each client.</p>
            </div>
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
            <CardChecklist className='so_icon' />
              <h4>Rigorous methodology</h4>
              <p>We employ a rigorous methodology for accurate, reliable, and actionable data solutions. Our expert team utilizes advanced tools, techniques, and continuous innovation to ensure precision and value in our analyses .</p>
            </div>
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="300">
            <FontAwesomeIcon icon={faChartLine} className='so_icon'/>
              <h4>Results-driven approach</h4>
              <p>We are committed to delivering measurable results that drive business value. Our data solutions are designed to deliver tangible outcomes, such as increased revenue, improved operational efficiency, and enhanced customer experience</p>
            </div>
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="400">
            <FontAwesomeIcon icon={faSquarePollHorizontal} className='so_icon'/>
              <h4>Continuous improvement</h4>
              <p>We're dedicated to innovation in the ever-evolving data landscape. Continuously adopting new tools and techniques, we ensure our data solutions remain accurate and effective. Our commitment includes ongoing support for sustained relevance.</p>
            </div>
          </div>
          <div className="col-md-6 mt-4 mt-md-0 mx-auto">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="500">
            <FontAwesomeIcon icon={faDatabase} className='so_icon'/>
              <h4>Ethical and transparent</h4>
              <p>We prioritize ethical responsibilities, maintaining the highest standards in our work. We offer transparency to clients regarding data collection, analysis, and usage, ensuring fairness, transparency, and utmost respect for individual privacy and data rights in our solutions.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    
  );
}

export default Solution;
