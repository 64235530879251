import { BrowserRouter as Router, Route , Routes} from 'react-router-dom';

import AboutPage from './Pages/AboutPage/AboutPage';
import HomePage from './Pages/HomePage/HomePage';
import DataScience from './Pages/DataScience/DataScience';
import DataAnalytics from './Pages/DataAnalytics/DataAnalytics';
import ArtificialIntelligence from './Pages/ArtificialIntelligence/ArtificialIntelligence';
import CaseStudiesLoop from './Pages/CaseStudiesLoop/CaseStudiesLoop';
import Cases from './sections/Cases/Cases';

import CaseStudiesData from './constants/CaseStudiesData';
import AuthenticationPage from './Pages/AuthenticationPage/AuthenticationPage';
import CaseStudiesPage from './Pages/CaseStudiesPage/CaseStudiesPage';
import ContactPage from './Pages/ContactPage/ContactPage';
import SmartDoc from './Pages/SmartDoc/SmartDoc';



const App = () => (
 
      <Router>
        <div>
          <Routes>
        
          <Route  path='/' element={<HomePage/>} />
          <Route path='/about' element={<AboutPage/>} />
          <Route path='/data_science' element={<DataScience/>} />
          <Route path='/data_analytics' element={<DataAnalytics/>} />
          <Route path='/ai' element={<ArtificialIntelligence/>} />
          <Route path='/case' element={<CaseStudiesLoop/>} />
          <Route exact path="/" element={<Cases />} />
          <Route exact path="/case/:id" element={<CaseStudiesLoop />} />
          <Route  path='/auth' element={<AuthenticationPage/>} />
          <Route  path='/casestudies' element={<CaseStudiesPage/>} />
          <Route  path='/contact' element={<ContactPage/>} />
          <Route  path='/smartdoc' element={<SmartDoc/>} />
          </Routes>
         </div>
          
       
        
      </Router>
    
);

export default App;
