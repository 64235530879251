import React from 'react';
import './AboutSection.css'; 
import heroImg from '../../assets/about_new.png';
import { Link } from 'react-router-dom';

const AboutSection = () => {
  return (
    <section id="about">
    <div className="responsive-container-block bigContainer" id="about_container">
    <header className="section-header">
          <h3>About us</h3>
          
        </header>
      {/* First Container */}
      <div className="responsive-container-block Container">
        
        <div className="allText aboveText">
          
          <p className="text-blk subHeadingText">
         
            As a Technology Company
          </p>
          <p className="text-blk description">
          we specialize in creating custom solutions for businesses. Using advanced technology like AI and machine learning, we help companies analyze their data for valuable insights. Our services include software development, cybersecurity, cloud solutions, and guiding digital transformation for streamlined growth.
          </p>
          <Link to='/about#aboutpage'>
          <button className="explore">
            Learn more
          </button>
          </Link>
        </div>
        <img className="mainImg" src={heroImg} alt="Mission" />
      </div>

      
    </div>
    </section>
  );
};

export default AboutSection;
