import React, { useState } from 'react';
import CaseStudiesData from '../../constants/CaseStudiesData';

import CategoryFilter from '../../components/FilterButton1/CategoryFilter';
import TypeFilter from '../../components/FilterButton1/TypeFilter';
import { Link } from 'react-router-dom'; 
const Cases = ({ selectedCategoryFilter }) => {
    const [filteredData, setFilteredData] = useState(CaseStudiesData);
    const defaultCategoryFilter = selectedCategoryFilter || 'ALL';
    const [activeCategoryFilter, setActiveCategoryFilter] = useState(defaultCategoryFilter);
    const [activeTypeFilter, setActiveTypeFilter] = useState('ALL');
  
    const handleCategoryFilter = (filter) => {
      setActiveCategoryFilter(filter);
  
      if (filter === 'ALL') {
        setFilteredData(CaseStudiesData);
      } else {
        const filtered = CaseStudiesData.filter((item) => item.category.includes(filter));
        setFilteredData(filtered);
      }
    };
  
    const handleTypeFilter = (filter) => {
      setActiveTypeFilter(filter);
  
      if (filter === 'ALL') {
        setFilteredData(CaseStudiesData);
      } else {
        const filtered = CaseStudiesData.filter((item) => item.type === filter);
        setFilteredData(filtered);
      }
    };
  
    const categoryFilters = ['ALL', 'Data Science', 'AI', 'Data Analytics'];
    const typeFilters = ['ALL', 'Research', 'Client'];
    
  
    return (
      <section id="recent-blog-posts" className="recent-blog-posts">
        <div className="container" data-aos="fade-up">
          <CategoryFilter categoryFilters={categoryFilters} activeCategoryFilter={activeCategoryFilter} handleClickCategoryFilter={handleCategoryFilter} />
          <TypeFilter typeFilters={typeFilters} activeTypeFilter={activeTypeFilter} handleClickTypeFilter={handleTypeFilter} />
          <div className="row">
            {filteredData.filter((study) => {
              if (activeCategoryFilter === 'ALL') {
                return true; // Show all items if category filter is set to "ALL"
              } else {
                return study.category.includes(activeCategoryFilter);
              }
            }).map((study) => (
              <div className="col-lg-4 mb-4" key={study.id} data-aos="fade-up" data-aos-delay="200">
                <div className="post-box" >
                  <div className="post-img">
                    <img src={study.image} className="img-fluid" alt="" />
                  </div>
                  <h3 className="post-title">{study.title}</h3>
                  
                  <Link to={`/case/${study.id}`} className="readmore stretched-link">
                  {/* Utilisez Link pour naviguer vers la page détaillée */}
                  
                </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  };
  
  export default Cases;
