import React from 'react';
import './SmartDoc.css';
import heroImg from '../../assets/brain.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from '../../components/navbar/navbar';
import data from '../../assets/gold_ser.png';
import cta from '../../assets/bakgold3.jpg';
import smart1 from '../../assets/smartdoc1.png';
import smart2 from '../../assets/smart2.png';
import digital from '../../assets/digtal.png';
import eff from '../../assets/eff2.png';
import money from '../../assets/money44.png';
import Footer from '../../components/footer/footer';
import ContactSection from '../../sections/ContactSection/ContactSection';



const SmartDoc = () => {
  return (
    <div className='homepage'>
    <div className='homepage_container'>
        <Navbar/>
        <section id="datascience" className="datascience ">
        
        <div className="container_science">
          
          <div className="row">
            <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up">
              <h1>
                
              Unlock the Future of Document Management with<span> Smart Doc Your Digital Rosetta Stone </span>
              </h1>
              <h2 data-aos-delay="400" style={{ fontSize: '20px' }}>
             
              In the world of endless paperwork, where bureaucratic red tape, costly processes, and
time-consuming document communication create a labyrinth of inefficiency, Smart Doc emerges
as the Rosetta Stone of the digital age.
Just as the ancient Rosetta Stone bridged languages and unlocked the secrets of hieroglyphics,
Smart Doc transforms the way businesses handle documents, turning complexity into clarity.

              </h2>
              
            </div>
            <div className="col-lg-6 datascience-img" data-aos="zoom-out" data-aos-delay="200">
              <img src={smart1}  className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section id="features" className="features">
      <div className="container">

      <div className="row gy-4 align-items-stretch justify-content-between features-item ">
          <div className="col-lg-6 d-flex align-items-center features-img-bg" data-aos="zoom-out">
            <img src={digital} style={{ maxWidth: '90%', height: 'auto' }} className="img-fluid" alt="" />
          </div>
          <div className="col-lg-5 d-flex justify-content-center flex-column" data-aos="fade-up">
            <h1> Frictionless Communication, <span>Limitless Possibilities:</span></h1>
            <br/>
            <p>Smart Doc tackles the monumental problem of cumbersome document exchanges head-on.
              Say goodbye to the paper-laden desks and hello to a seamless, paperless world. Invoices,
              purchase orders, quotations, financial statements, and customs declarations – all become
              effortlessly manageable with our cloud-based platform.</p>
          </div>
        </div>

        <div className="row gy-4 align-items-center features-item">
          <div className="col-lg-5 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
            <h1>Empower Your Team, <span> Elevate Your Efficiency:</span></h1>
            <br/>
            <p>
              Free your human teams from the drudgery of data entry. Smart Doc's sophisticated platform
              doesn't just store documents; it breathes life into them. By linking all related documents in a
              transaction, it ensures that every piece of the puzzle is right where you need, when you need it.
            </p>
          </div>
          <div className="col-lg-7 order-1 order-lg-2 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="100">
            <div className="image-stack">
              <img src={eff} alt="" className="stack-front" />
              <img src={smart2} alt="" className="stack-back" />
            </div>
          </div>
        </div>

        
      </div>
    </section>
    <section id="cta" className="cta">
      <div className="container" data-aos="zoom-out">

        <div className="row g-5">

          <div className="col-lg-8 col-md-6 content d-flex flex-column justify-content-center order-last order-md-first">
            <h1><span>Automate, Integrate, Innovate:</span></h1>
            <br></br>
            <p> Stay ahead of the curve with Smart Doc's automated data flows that keep your transactions on
track and on time. But we don't stop there. Our platform is designed to sync seamlessly with
your existing enterprise systems, including SAP and Oracle, ensuring that your business runs
like a well-oiled machine.
</p>
            {/* <a className="cta-btn align-self-start" href="#">Call To Action</a> */}
          </div>

          <div className="col-lg-4 col-md-6 order-first order-md-last d-flex align-items-center">
            <div className="img">
              <img src={data} alt="" className="img-fluid"/>
            </div>
          </div>

        </div>

      </div>
    </section>
    <section id="call-to-action2" className="call-to-action2">
      <img src={cta} alt="" />

      <div className="container">
        <div className="row justify-content-center" data-aos="zoom-in" data-aos-delay="100">
          <div className="col-xl-10">
            <div className="text-center">
              <h3>Discover Smart Doc Today:</h3>
              <p>Step into the future of document management. Experience the power of Smart Doc and
transform your document communication into a streamlined, efficient, and paperless process.
</p>
<a className="cta-btn" href="#" style={{textDecoration: 'none'}}>Coming soon</a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="features" className="features">
      <div className="container">

      <div className="row gy-4 align-items-stretch justify-content-between features-item ">
          <div className="col-lg-6 d-flex align-items-center features-img-bg" data-aos="zoom-out" >
            <img src={money} style={{ maxWidth: '85%', height: 'auto' }} className="img-fluid" alt="" />
          </div>
          <div className="col-lg-5 d-flex justify-content-center flex-column" data-aos="fade-up">
            <h1> Join The<span> Revolution :</span></h1>
            <br/>
            <p>In a world where time is money, and efficiency is the key to success, Smart Doc is not just a solution; it's your digital transformation partner. Embrace the future of document management and turn your business processes into a model of modern efficiency with Smart Doc – the Rosetta Stone of the digital document world.</p>
          </div>
        </div>
        </div>
        </section>
    
    <ContactSection/>
    <Footer/>
      
    </div>
    </div>
  );
};

export default SmartDoc;
