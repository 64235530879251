import React from 'react';
import data from '../../assets/data_analytics55.png';
import data2 from '../../assets/brain50.png';
import data3 from '../../assets/data-modelling50.png';
import data4 from '../../assets/natural-language-processing.png';
import data5 from '../../assets/deep-learning.png';
import data6 from '../../assets/chart.png';
import './DataScience.css';
import Navbar from '../../components/navbar/navbar';
import FreeConsultation from '../../sections/FreeConsultation/FreeConsultation';
import ContactSection from '../../sections/ContactSection/ContactSection';
import Footer from '../../components/footer/footer';
import Cases from '../../sections/Cases/Cases';
const DataScience = () => {
  return (
    <div className='datasc'>
    <div className='datasc_container'>
    <Navbar/>
    <section id="datascience" className="datascience ">
        
      <div className="container_science">
        
        <div className="row">
          <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up">
            <h1>
              Deploy Advanced Machine Learning With Our<span> Data Science Consultants</span>
            </h1>
            <h2 data-aos-delay="400">
            Our team of seasoned Data Science Consultants empowers your organization to implement sophisticated Machine Learning models and develop a meticulously tailored AI strategy designed specifically for your long-term business vision
            </h2>
            <div data-aos="fade-up" data-aos-delay="600">
              <div className="text-center text-lg-start">
              <a href="#about" className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">Get Started</a>
              </div>
            </div>
          </div>
          <div className="col-lg-6 datascience-img" data-aos="zoom-out" data-aos-delay="200">
            <img src={data} className="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </section>
    



    <section id="sc_services" class="sc_services sc_services">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Services</h2>
          <h1>
            Launch Your Next<span> Machine<br></br> Learning </span> Project
            </h1>
          <p>
            <br></br>
            
DSF Analytics offers a comprehensive Data Science tool tailored <br></br> to address  your specific project scale and data complexity.</p>
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-6 icon-box mx-auto" data-aos="zoom-in" data-aos-delay="100">
            <div class="icon"><img src={data2} class="img-ser" alt=""/></div>
            <h4 class="title2">Machine Learning</h4>
            <p class="description">Our team of experts leverages advanced machine learning techniques to provide you with insightful data-driven recommendations that drive your company forward</p>
          </div>
          <div class="col-lg-3 col-md-6 icon-box mx-auto" data-aos="zoom-in" data-aos-delay="200">
            <div class="icon"><img src={data3} class="img-ser" alt=""/></div>
            <h4 class="title2">Data Modeling</h4>
            <p class="description">We use cutting-edge data modeling techniques to help you analyze and predict trends, spot new opportunities and make data-driven decisions with confidence</p>
          </div>
          <div class="col-lg-3 col-md-6 icon-box mx-auto" data-aos="zoom-in" data-aos-delay="300">
            <div class="icon"><img src={data4} class="img-ser" alt=""/></div>
            <h4 class="title2">Natural Language Processing</h4>
            <p class="description">Our NLP experts can help you unlock the value of your unstructured data by leveraging the latest techniques in sentiment analysis, entity recognition, and more</p>
          </div>
          </div>
          <div class="row">
          <div class="col-lg-3 col-md-6 icon-box mx-auto mb-1" data-aos="zoom-in" data-aos-delay="100">
            <div class="icon"><img src={data5} class="img-ser" alt=""/></div>
            <h4 class="title2">Deep Learning</h4>
            <p class="description">We use deep learning algorithms to help you uncover hidden patterns in your data, automate decision-making processes, and enhance your customer experience</p>
          </div>
          <div class="col-lg-3 col-md-6 icon-box mx-auto mb-1" data-aos="zoom-in" data-aos-delay="200">
            <div class="icon"><img src={data6} class="img-ser" alt=""/></div>
            <h4 class="title2">Time Series Analysis</h4>
            <p class="description">We use advanced time series analysis techniques to help you uncover trends, patterns and relationships in your data, and make informed decisions for your business</p>
          </div>
          
        </div>

      </div>
    </section>
    
    <FreeConsultation/>
    
    <Footer/>
    
    </div>
    </div>
  );
};

export default DataScience;
