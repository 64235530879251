import React from 'react';
import data from '../../assets/gold_ai.png';
import data2 from '../../assets/ai50.png';
import data3 from '../../assets/engineering.png';
import data4 from '../../assets/robotic-arm.png';
import data5 from '../../assets/compvision.png';
import data6 from '../../assets/face-detection.png';
// import '../../pages/DataScience/DataScience.css';
import Navbar from '../../components/navbar/navbar';
import FreeConsultation from '../../sections/FreeConsultation/FreeConsultation';
import ContactSection from '../../sections/ContactSection/ContactSection';
import Footer from '../../components/footer/footer';
import Cases from '../../sections/Cases/Cases';
const ArtificialIntelligence = () => {
  return (
    <div className='datasc'>
    <div className='datasc_container'>
    <Navbar/>
    <section id="datascience" className="datascience ">
        
      <div className="container_science">
        
        <div className="row">
          <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up">
            <h1>
              
            Establish Robust Strategy Through Our<span> Artificial Intelligence Consultants</span>
            </h1>
            <h2 data-aos-delay="400">
           
            Our team enables organizations to establish a robust AI strategy for embracing AI technologies comprehensively. We offer end-to-end AI implementation services, managing everything from data preparation and model development to deployment and ongoing maintenance
            </h2>
            <div data-aos="fade-up" data-aos-delay="600">
              <div className="text-center text-lg-start">
              <a href="#about" className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">Get Started</a>
              </div>
            </div>
          </div>
          <div className="col-lg-6 datascience-img" data-aos="zoom-out" data-aos-delay="200">
            <img src={data} className="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </section>
    



    <section id="sc_services" class="sc_services sc_services">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Services</h2>
          <h1>
            Crafted <span> AI Consultancy  <br></br> </span>   Exclusively Designed for Your Business Needs
            </h1>
          <p>
            <br></br>
            

            Irrespective of your immediate objectives or future aspirations, <br></br>our pioneering Data Analysts are here to expedite your market<br></br> presence and drive increased value in your endeavors</p>
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-6 icon-box mx-auto" data-aos="zoom-in" data-aos-delay="100">
            <div class="icon"><img src={data2} class="img-ser" alt=""/></div>
            <h4 class="title2">AI Strategy and Consulting</h4>
            <p class="description">We help companies develop a comprehensive AI strategy and provide expert consulting services to ensure successful implementation and adoption of AI technologies</p>
          </div>
          <div class="col-lg-3 col-md-6 icon-box mx-auto" data-aos="zoom-in" data-aos-delay="200">
            <div class="icon"><img src={data3} class="img-ser" alt=""/></div>
            <h4 class="title2">AI Implementation</h4>
            <p class="description">We provide end-to-end AI implementation services, from data preparation and model development to deployment and maintenance, to help companies leverage AI for competitive advantage</p>
          </div>
          <div class="col-lg-3 col-md-6 icon-box mx-auto" data-aos="zoom-in" data-aos-delay="300">
            <div class="icon"><img src={data4} class="img-ser" alt=""/></div>
            <h4 class="title2">Robotics and Automation</h4>
            <p class="description">We help companies automate routine tasks and improve operational efficiency through the use of robotics and automation technologies, including process automation, industrial robots, and more</p>
          </div>
          </div>
          <div class="row">
          <div class="col-lg-3 col-md-6 icon-box mx-auto mb-1" data-aos="zoom-in" data-aos-delay="100">
            <div class="icon"><img src={data5} class="img-ser" alt=""/></div>
            <h4 class="title2">Computer Vision</h4>
            <p class="description">We specialize in developing computer vision solutions that enable companies to extract insights from visual data, including image recognition, object detection, and more</p>
          </div>
          <div class="col-lg-3 col-md-6 icon-box mx-auto mb-1" data-aos="zoom-in" data-aos-delay="200">
            <div class="icon"><img src={data6} class="img-ser" alt=""/></div>
            <h4 class="title2">Image Recognition</h4>
            <p class="description">We develop custom image recognition solutions that enable companies to identify objects, people, and other elements in visual data with high accuracy and efficiency</p>
          </div>
          
        </div>

      </div>
    </section>
    

    
    <FreeConsultation/>
    
    
    <Footer/>
    
    </div>
    </div>
  );
};

export default ArtificialIntelligence;
