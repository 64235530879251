import React, { useState } from 'react';
import CaseStudiesData from '../../constants/CaseStudiesData';
import { Link } from 'react-router-dom'; 
import Navbar from '../../components/navbar/navbar';
import FreeConsultation from '../../sections/FreeConsultation/FreeConsultation';
import Footer from '../../components/footer/footer';
import Solution2 from '../../sections/Solution2/Solution2';
const CaseStudiesPage = ({ selectedCategoryFilter }) => {
    const [filteredData, setFilteredData] = useState(CaseStudiesData);
    const defaultCategoryFilter = selectedCategoryFilter || 'ALL';
    const [activeCategoryFilter, setActiveCategoryFilter] = useState(defaultCategoryFilter);
    
    return (
        <div className='homepage'>
        <div className='homepage_container'>
        <Navbar/>
      <section id="recent-blog-posts" className="recent-blog-posts">
      <div class="section-title">
          <h2>Our Projects</h2>
 
        </div>
        
        <div className="container" data-aos="fade-up">
         
          <div className="row">
            {filteredData.filter((study) => {
              if (activeCategoryFilter === 'ALL') {
                return true;
              } else {
                return study.category.includes(activeCategoryFilter);
              }
            }).map((study) => (
                <div className="col-lg-6 mb-4" key={study.id} data-aos="fade-up" data-aos-delay="200" style={{ maxWidth: '400px', margin: '10px auto' , marginBottom : '10px' }}>
                  <div className="post-box">
                    <div className="post-img">
                      <img src={study.image} className="img-fluid" alt="" />
                    </div>
                    <h3 className="post-title">{study.title}</h3>
                    <p>{study.description}</p>
                    <Link to={`/case/${study.id}`} className="readmore stretched-link">
                      
                      <i className="bi bi-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
      
      <FreeConsultation/>
      <Footer/>
      </div>
      </div>
    );
  };
  
  export default CaseStudiesPage;
