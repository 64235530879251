import React from 'react';
import './footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight  } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faGoogle, faLinkedin, faFacebook } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">

            <div className="col-lg-3 col-md-6 footer-contact">
              <h3>DSF ANALYTICS<span>.</span></h3>
              <p>
              2040 Columbia  <br />
              St San Diego, CA 92101 <br />
                United States <br /><br />
                <strong>Phone:</strong> +1 224 433-3904<br />
                <strong>Email:</strong> contact@dsfanalytics.com<br />
              </p>
            </div>

            <div className="col-lg-2 col-md-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li><FontAwesomeIcon icon={faChevronRight} className='foot_icon' />  <a href="/">Home</a></li>
                <li><FontAwesomeIcon icon={faChevronRight} className='foot_icon' />  <a href="/about">About us</a></li>
                
                <li><FontAwesomeIcon icon={faChevronRight} className='foot_icon' />  <a href="/casestudies">Case Studies</a></li>
                <li><FontAwesomeIcon icon={faChevronRight} className='foot_icon' />  <a href="/smartdoc">Smart Doc</a></li>
                
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 footer-links">
              <h4>Our Services</h4>
              <ul>
                <li><FontAwesomeIcon icon={faChevronRight} className='foot_icon' /> <a href="/data_science">Data Science</a></li>
                <li><FontAwesomeIcon icon={faChevronRight} className='foot_icon' />  <a href="/ai">Artificial Intilegence</a></li>
                <li><FontAwesomeIcon icon={faChevronRight} className='foot_icon' />  <a href="/data_analytics">Data Analytics</a></li>
                
              </ul>
            </div>

            {/* <div className="col-lg-4 col-md-6 footer-newsletter">
              <h4>Join Our Newsletter</h4>
              <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
              <form action="" method="post">
                <input type="email" name="email" /><input type="submit" value="Subscribe" />
              </form>
            </div> */}

          </div>
        </div>
      </div>

      <div class="container d-md-flex py-4">

      <div class="me-md-auto text-center text-md-start">
        <div class="copyright">
          &copy; Copyright <strong><span>DSF Analytics</span></strong>. All Rights Reserved
        </div>
       
      </div>
      <div class="social-links text-center text-md-end pt-3 pt-md-0">
        <a href="#" class="twitter"><FontAwesomeIcon icon={faTwitter} className="bx bxl-twitter" size="sm" /></a>
        <a href="#" class="facebook"><FontAwesomeIcon icon={faFacebook} className="fab fa-facebook" size="sm"/></a>
        
        <a href="#" class="google-plus"><FontAwesomeIcon icon={faGoogle} className="fab fa-google"size="sm"/></a>
        <a href="#" class="linkedin"> <FontAwesomeIcon icon={faLinkedin} className="fab fa-linkedin" size="sm"/></a>
      </div>
    </div>

      
    </footer>
  );
};

export default Footer;
