import React from 'react';
import './AboutPage.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from '../../components/navbar/navbar';
import Footer from '../../components/footer/footer';
import FreeConsultation from '../../sections/FreeConsultation/FreeConsultation';
import ContactSection from '../../sections/ContactSection/ContactSection';
import heroImg from '../../assets/biz-ppl.jpg';
import Del from '../../assets/Del_photo.png';
import Yassine from '../../assets/Yassine_photo.png';
import { CardChecklist } from 'react-bootstrap-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshakeAngle ,faSquarePollHorizontal ,faDiagramProject  , faCube} from '@fortawesome/free-solid-svg-icons';


const AboutPage = () => {
  return (
    <div className='homepage'>
    <div className='homepage_container'>
        <Navbar/>
    
    <br></br>
    <section id="features" class="features" data-aos="fade-up">
      <div class="container">

      <header className="section-header">
          <h3>Company Overview</h3>
          
        </header>

        <div class="row content">
          <div class="col-md-6 order-1 order-md-2" data-aos="fade-left">
            <img src={heroImg} class="img-fluid" alt="" style={{ maxWidth: '500px', height: 'auto', marginLeft: '20px' , marginTop: '30px'}}/>
          </div>
          <div class="col-md-6 pt-5 order-2 order-md-1" data-aos="fade-right">
            {/* <h3>Company Overview</h3> */}
            
            <p>
              We are a forward-thinking technology firm specializing in tailoring solutions for a diverse range of businesses. Our team consists of adept professionals skilled in data science, machine learning, and AI, facilitating a holistic problem-solving approach. We advocate for the strategic deployment of data, recognizing its transformative potential in gaining a competitive advantage. Notably, the International Institute for Analytics reported a remarkable $430 billion surge in productivity for businesses leveraging data between 2017 and 2020, highlighting its unparalleled impact on organizational success.
            </p>
          </div>
        </div>
        
        

        

      </div>
      
    </section>
    <section id="abouttt" class="abouttt section-bg">
    <div class="section-title">
          <h2>Approach and Services</h2>
         
        </div>
      <div class="container" data-aos="fade-up">

        <div class="row no-gutters">
          <div class="content col-xl-5 d-flex align-items-stretch">
            <div class="content">
              <h3>How Do We Work ?</h3>
              <p>
              Our approach is anchored in close collaboration with clients, where we deeply understand their unique needs, aspirations, and operational intricacies. By prioritizing this understanding, we ensure the delivery of bespoke, innovative solutions that cater specifically to our clients' requirements
              </p>
              
            </div>
          </div>
          <div class="col-xl-7 d-flex align-items-stretch">
            <div class="icon-boxes d-flex flex-column justify-content-center">
              <div class="row">
                <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
                <FontAwesomeIcon icon={faHandshakeAngle} className='ao_icon'/>
                  <h4>Approach to working closely with clients to understand their needs.</h4>
                  
                </div>
                <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
                <FontAwesomeIcon icon={faCube} className='ao_icon'/>
                  <h4>Commitment to delivering tailored, cutting-edge solutions.</h4>
                  
                </div>
                <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300">
                <FontAwesomeIcon icon={faSquarePollHorizontal} className='ao_icon'/>
                  <h4>Focus on delivering measurable results for growth and profitability.</h4>
                  
                </div>
                <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="400">
                <FontAwesomeIcon icon={faDiagramProject} className='ao_icon'/>
                  <h4>Mentioning various use cases, such as data strategy formulation, machine learning, and predictive analytics, tailored to different companies' requirements.</h4>
                  
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
    <section>
      <div class="container" data-aos="fade-up">
        <div class="row">
          <div class="col-12 text-center mb-5">
            <div class="row justify-content-center">
            <header className="section-header">
          <h3>Our Team</h3>
          
        </header>
            </div>
          </div>
          <div class="col-lg-4 text-center mb-5 mx-auto">
            <img src={Del} alt="" class="img-fluid rounded-circle w-50 mb-4"/>
            <h4>Del Homi</h4>
            <span class="d-block mb-3 text-uppercase">Director of AI</span>
            <p>Al and Machine Learning expert
with 10 years of experience in
the field, including 2 years at
Cognizant as a Machine
Learning Engineer.
Graduated with a Bachelor of
Science in Data Science from the
University of Texas in Dallas,
and holds
multiple certifications from
Amazon, Google and IBM.</p>
          </div>
          <div class="col-lg-4 text-center mb-5 mx-auto">
            <img src={Yassine} alt="" class="img-fluid rounded-circle w-50 mb-4"/>
            <h4>Yassine Ibnattya</h4>
            <span class="d-block mb-3 text-uppercase">Director of Data Science</span>
            <p>Data Science expert with over 8
years of experience in analytics,
spanning various industries.
Graduated with a Bachelor Of
Science in Civil & Environmental
Engineering from the University Of
Illinois Urbana-Champaign,
a Master of Science in Analytics
from Georgia Institute of
Technology, and an Executive MBA
from Ecoles des Ponts.</p>
          </div>
          
          
        </div>
      </div>
    </section>
    
    <Footer/>
    
    
    </div>
    </div>
  );
};

export default AboutPage;
